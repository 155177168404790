<template>
  <div class="examQuestionsEditBox">
    <!-- 左边 -->
    <div class="examQuestionsEditLeft">
      <!-- 添加关联 -->
      <div>
        <el-form
          ref="testQuestionParam"
          :model="testQuestionParam"
          label-width="110px"
        >
          <el-form-item label="题目名称">
            <el-input
              v-model="testQuestionParam.operationTestName"
              type="textarea"
              placeholder="题目名称"
              style="max-width: 400px"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- 
            minlength="10"
              maxlength="10"
          -->
          <el-form-item label="考题编码">
            <el-input
              v-model="testQuestionParam.operationTestCode"
              placeholder="考题编码"
              style="max-width: 400px"
              v-changeVal
            ></el-input>
          </el-form-item>
          <el-form-item label="相关学科">
            <el-select
              v-model="testQuestionParam.subjectType"
              placeholder="请选择相关学科"
              style="width: 400px"
            >
              <el-option label="物理" :value="1"></el-option>
              <el-option label="生物" :value="2"></el-option>
              <el-option label="化学" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电子试卷">
            <el-switch
              v-model="testQuestionParam.paperless"
              active-color="#13ce66"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </el-form-item>
          <el-form-item label="是否支持AI评分">
            <el-switch
              v-model="testQuestionParam.aiMarking"
              active-color="#13ce66"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </el-form-item>
          <el-form-item
            label="是否需要显微镜"
            v-if="testQuestionParam.subjectType == 2"
            label-width="124px"
          >
            <el-radio-group v-model="testQuestionParam.microscope">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="评分标准">
            <el-button
              v-throttle
              type="primary"
              size="mini"
              @click="dialogTestQuestionParam = true"
              icon="el-icon-circle-plus-outline"
              >添加一行</el-button
            >
          </el-form-item>
        </el-form>
        <!-- :header-cell-style="{background: '#DFE6EC'}" -->
        <el-table
          header-align="center"
          border
          stripe
          :data="questionsParams"
          style="width: 100%"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column align="center" prop="date" label="序号" width="60">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <!-- operationItemName -->
          <!-- <el-table-column align="center" prop="sortNum" label="排序字段" width="60"></el-table-column> -->
          <el-table-column
            prop="operationItemName"
            label="评分项"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="score"
            label="分数"
            width="80"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <!--icon="el-icon-delete" -->
              <el-button
                v-throttle
                size="mini"
                type="danger"
                @click="deleteQuestionsParams(scope.$index, scope.row.sortNum)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 可选题型 v-if="0" -->
      <div class="chooseTopicBox" v-if="testQuestionParam.paperless">
        <div class="chooseTopicTit">可选题型</div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          "
        >
          <div class="addTopicBtn" @click="addTopicBtn(1)">填空题</div>
          <div class="addTopicBtn" @click="addTopicBtn(2)">简答题</div>
          <div class="addTopicBtn" @click="addTopicBtn(3)">画图题</div>
          <div class="addTopicBtn" @click="addTopicBtn(4)">单选题</div>
          <div class="addTopicBtn" @click="addTopicBtn(5)">多选题</div>
          <div class="addTopicBtn" @click="addTopicBtn(6)">公式画图题</div>
          <div class="addTopicBtn" @click="addTopicBtn(7)">读数识别填空题</div>
        </div>
      </div>
      <!-- 已选考题 v-if="0" -->
      <div
        class="chooseTopicBox"
        style="border: 0"
        v-if="testQuestionParam.paperless"
      >
        <div style="margin-bottom: 10px" v-if="0">
          答题字体大小
          <el-input-number
            size="mini"
            v-model="fontSize"
            :min="12"
            :max="38"
          ></el-input-number>
        </div>
        <div class="chooseTopicTit">
          已选考题
          <span style="font-size: 16px; color: #f5a64d"
            >（在试卷中右键按住可拖拽）</span
          >
        </div>
        <div class="chooseTopicList">
          <div
            class="chooseTopic"
            v-for="(item, i) in electronicPapersQuestionParams"
            :key="i"
            @click="checkedBtn(item, i)"
          >
            {{ i + 1 }}.{{ item.questionType | filterSubject }}
            <i class="el-icon-error" @click.stop="deleteBtn(i)"></i>
          </div>
        </div>
        <!-- 题目答案 -->
        <div class="chooseTopicChecked" v-show="topicIndex">
          <el-form ref="topicForm" :model="topicForm" label-width="120px">
            <div style="padding: 0 20px 16px">
              {{ topicIndex }}.{{ topicForm.questionType | filterSubject }}
            </div>
            <el-form-item label="答案(选填)：">
              <template
                v-if="
                  topicForm.questionType == 3 || topicForm.questionType == 6
                "
              >
                <!-- 画图题 -->
                <div style="display: flex; align-items: center">
                  <div
                    class="answerImg"
                    v-if="topicForm.answer"
                    @click="previewAnswerImg = true"
                    style="margin-right: 10px"
                  >
                    <img :src="this.$imageAddress(topicForm.answer)" />
                  </div>
                  <el-upload
                    :limit="1"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :http-request="uploadImgAnswer"
                    :show-file-list="false"
                  >
                    <el-button v-throttle size="medium" type="primary"
                      >答案图片上传</el-button
                    >
                  </el-upload>
                  <el-upload
                    :limit="1"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :http-request="uploadImgBackgroundImgUrl"
                    :show-file-list="false"
                    style="margin-left: 10px"
                  >
                    <el-button v-throttle size="medium" type="primary"
                      >画框背景上传</el-button
                    >
                  </el-upload>
                </div>
              </template>
              <!-- 单选题 -->
              <template v-else-if="topicForm.questionType == 4">
                <el-table
                  header-align="center"
                  border
                  :data="topicForm.electronicPapersOptionParams"
                  style="width: 100%"
                >
                  <el-table-column
                    align="center"
                    type="index"
                    :index="(index) => topicIndex + '.' + (index + 1)"
                    label="序号"
                    width="60px"
                  >
                    <!-- <template slot-scope="scope">{{scope}}</template> -->
                  </el-table-column>
                  <el-table-column align="center" label="选项">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.optionContent"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    type="index"
                    :index="(index) => topicIndex + '.' + (index + 1)"
                    width="120px"
                    align="center"
                    label="选项按钮"
                  >
                    <template slot-scope="scope">
                      <div class="radioBtnS">
                        {{ topicIndex + '.' + (scope.$index + 1) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="正确答案">
                    <template slot-scope="scope">
                      <el-radio-group
                        v-model="scope.row.correct"
                        @change.native="
                          radioChange(scope.row.correct, scope.$index)
                        "
                      >
                        <el-radio
                          class="radio-el"
                          size="medium"
                          :label="true"
                          >{{ '' }}</el-radio
                        >
                        <el-radio
                          class="radio-el radio-el2"
                          size="medium"
                          :label="false"
                          v-show="scope.row.correct"
                        >
                          {{ '' }}
                        </el-radio>
                      </el-radio-group>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
                </el-table>
                <el-row style="margin-top: 20px">
                  <el-button
                    v-throttle
                    icon="el-icon-minus"
                    @click="deleteChooseBtn"
                    >删除选项</el-button
                  >
                  <el-button
                    v-throttle
                    icon="el-icon-plus"
                    @click="addChooseBtn"
                    >添加选项</el-button
                  >
                </el-row>
                <el-row style="margin-top: 20px"
                  >正确答案：{{ topicForm.answer }}</el-row
                >
              </template>
              <!-- 多选题 -->
              <template v-else-if="topicForm.questionType == 5">
                <el-table
                  header-align="center"
                  border
                  :data="topicForm.electronicPapersOptionParams"
                  style="width: 100%"
                >
                  <el-table-column
                    align="center"
                    type="index"
                    :index="(index) => topicIndex + '.' + (index + 1)"
                    label="序号"
                    width="60px"
                  >
                    <!-- <template slot-scope="scope">{{scope}}</template> -->
                  </el-table-column>
                  <el-table-column align="center" label="选项">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.optionContent"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    type="index"
                    :index="(index) => topicIndex + '.' + (index + 1)"
                    width="120px"
                    align="center"
                    label="选项按钮"
                  >
                    <template slot-scope="scope">
                      <div class="radioBtnS multipleRadioBtnS">
                        {{ topicIndex + '.' + (scope.$index + 1) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="正确答案">
                    <template slot-scope="scope">
                      <!-- <el-radio-group
                        v-model="scope.row.correct"
                        @change.native="radioChange(scope.row.correct ,scope.$index)"
                      >
                      </el-radio-group>-->
                      <el-checkbox-group
                        v-model="scope.row.correct"
                        @change.native="multipleRadioChange"
                      >
                        <el-checkbox :label="true">{{ '' }}</el-checkbox>
                      </el-checkbox-group>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
                </el-table>
                <el-row style="margin-top: 20px">
                  <el-button
                    v-throttle
                    icon="el-icon-minus"
                    @click="deleteChooseBtn"
                    >删除选项</el-button
                  >
                  <el-button
                    v-throttle
                    icon="el-icon-plus"
                    @click="addChooseBtn"
                    >添加选项</el-button
                  >
                </el-row>
                <el-row style="margin-top: 20px">
                  正确答案：{{ topicForm.answer | multipleRadioAnswer }}
                </el-row>
              </template>
              <template v-else>
                <el-input
                  v-model="topicForm.answer"
                  :placeholder="
                    '请输入' +
                    Math.floor(topicForm.positionHeight / (this.fontSize + 4)) *
                      Math.floor((topicForm.positionWidth - 2) / fontSize) +
                    '字以内'
                  "
                ></el-input>
              </template>
            </el-form-item>
            <el-form-item label="评分项(选填)：">
              <el-button
                v-throttle
                type="primary"
                size="medium"
                @click="dialogVisibleShow"
                style="margin-right: 10px"
                >关联</el-button
              >
              {{
                topicForm.operationQuestionNum
                  ? `(已关联评分项序号：${this.associationIndex})`
                  : '(未关联)'
              }}
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="clearfix" style="margin-top: 20px">
        <el-button
          v-throttle
          @click="
            () => {
              this.$router.back()
            }
          "
          size="medium"
          class="rl"
          >返回</el-button
        >
        <el-button
          v-throttle
          @click="SaveSubmit"
          type="primary"
          size="medium"
          class="rl"
          >保存</el-button
        >
      </div>
    </div>
    <!-- 右边 1020px 固定-->
    <div class="examQuestionsEditRight">
      <div class="upBtn">
        上传试卷
        <span>
          <el-upload
            style="display: inline-block; margin-right: 10px"
            :limit="1"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="uploadPdfBtn"
            :show-file-list="false"
            ref="upload"
            :disabled="!!tableData.length"
          >
            <el-button
              v-throttle
              size="mini"
              :disabled="!!tableData.length"
              type="primary"
              >上传试卷</el-button
            >
          </el-upload> </span
        >该试卷文件用于考生在考生终端内阅读试卷内容及信息,只支持PDF及PNG格式上传,最大20MB
      </div>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 96%"
        align="“center"
      >
        <el-table-column
          align="center"
          v-if="tableData.fileName"
          prop="fileName"
          label="文件名称"
        ></el-table-column>
        <el-table-column
          align="center"
          v-if="tableData.size"
          prop="size"
          label="文件大小(单位Byte)"
          width="180"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template>
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="previewPdf = true"
              >预览</el-button
            >
            <el-button v-throttle size="mini" type="danger" @click="removePdf"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 试卷 清除右键默认事件 -->
      <div class="testPaperBox" ref="testPaperBox" @contextmenu.prevent>
        <div class="testPaper" ref="testPaper">
          <!-- 试卷图片 -->
          <img class="testPaperImg" :src="imgPdfUrl" />
          <!-- 定位盒子 鼠标右键触发拖拽-->
          <template v-for="(item, i) in electronicPapersQuestionParams">
            <div
              class="textareaBox"
              :key="i"
              v-drag="item"
              :style="`
              width: ${item.positionWidth}px;
              height:${item.positionHeight}px;
              top:${item.positionTop}px;
              left:${item.positionLeft}px;
              ${
                item.questionType == 3 && !!item.backgroundImgUrl
                  ? ` background: url(${imageAddress(
                      item.backgroundImgUrl
                    )}) center center / 100% 100% no-repeat;`
                  : ''
              }
             `"
            >
              <textarea
                :placeholder="item.questionType | filterSubject(i + 1 + '.')"
                readonly
                :style="
                  'width:' +
                  item.positionWidth +
                  'px;height:' +
                  item.positionHeight +
                  'px;'
                "
              ></textarea>
              <span v-if="item.questionType < 3">
                限{{
                  Math.floor(item.positionHeight / (fontSize + 4)) *
                  Math.floor((item.positionWidth - 2) / fontSize)
                }}字
              </span>
            </div>
            <div
              :class="
                item.questionType == 4
                  ? 'radioCon'
                  : 'radioCon multipleRadioCon'
              "
              :key="i + 'con' + j"
              v-drag="con"
              v-for="(con, j) in item.electronicPapersOptionParams"
              :style="
                'width:' +
                con.optionBoxPositionWidth +
                'px;height:' +
                con.optionBoxPositionHeight +
                'px;top:' +
                con.optionBoxPositionTop +
                'px;left:' +
                con.optionBoxPositionLeft +
                'px'
              "
            >
              <textarea
                :placeholder="i + 1 + '.' + (j + 1)"
                readonly
                :style="
                  'width:' +
                  con.optionBoxPositionWidth +
                  'px;height:' +
                  con.optionBoxPositionHeight +
                  'px;'
                "
              ></textarea>
            </div>
            <!-- <div :key="is" v-for="(list, is) in item">{{list}}</div> -->
          </template>
        </div>
      </div>
    </div>
    <!-- 添加评分标准弹框 -->
    <el-dialog
      title="添加评分标准"
      :visible.sync="dialogTestQuestionParam"
      width="30%"
    >
      <el-form
        ref="dialogTestQuestionParamText"
        :model="dialogTestQuestionParamText"
        label-width="80px"
      >
        <el-form-item label="评分项">
          <el-input
            type="textarea"
            size="small"
            v-model="dialogTestQuestionParamText.operationTestName"
            style="max-width: 400px"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="分数">
          <el-input
            size="small"
            type="number"
            v-model.number="dialogTestQuestionParamText.score"
            style="max-width: 400px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-throttle
          size="small"
          @click="dialogTestQuestionParam = false"
          >取 消</el-button
        >
        <el-button
          v-throttle
          size="small"
          type="primary"
          @click="addQuestionsParams"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 关联弹框 -->
    <el-dialog
      :title="topicForm.questionType | filterSubject(topicIndex + '.')"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        关联：
        <el-select v-model="associationAnswer" placeholder="关联到">
          <el-option
            v-for="(item, i) in questionsParams"
            :key="i"
            :label="item.operationItemName"
            :value="item.sortNum"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-throttle @click="dialogVisible = false">取 消</el-button>
        <el-button v-throttle type="primary" @click="association"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- pdf预览弹框 -->
    <el-image-viewer
      v-if="previewPdf"
      :on-close="() => (this.previewPdf = false)"
      :url-list="[imgPdfUrl]"
    ></el-image-viewer>
    <!-- 答案图片预览弹框 -->
    <el-image-viewer
      v-if="previewAnswerImg"
      :on-close="() => (this.previewAnswerImg = false)"
      :url-list="[this.$imageAddress(topicForm.answer)]"
    ></el-image-viewer>
  </div>
</template>

<script>
import * as questions from '@/api/examQuestions/examQuestions.js'
// 引入ElImageViewer组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'examQuestionsEdit',
  // 图片预览组件
  components: { ElImageViewer },
  data() {
    return {
      // 序号
      associationIndex: '',

      // 字数限制 H/(FS+4) * (W-2)/FS
      // 答题默认字体大小 行高+4  间距一共也加2
      fontSize: 16,
      // // 回显更新后所需要携带的ID 取消
      // examQuestionOperationTestId: '',
      // itemId: '',
      electronicPapersQuestionId: '',
      // operationItemId: '',
      testId: '',
      // pdf 图片预览
      previewAnswerImg: false,
      // pdf 图片预览
      previewPdf: false,
      // url: 'http://192.168.6.226:9000',
      // 序号
      testIndex: 0,
      // 试卷的显示
      tableData: [],
      // 考试背景及预览
      imgPdfUrl: '',
      dialogVisible: false,
      // 序号
      topicIndex: 0,
      // 已选考题关联
      topicForm: {},
      dialogImageUrl: '',
      dialogVisibleImg: false,
      dialogTestQuestionParam: false,
      dialogTestQuestionParamText: {},
      // 关联答案
      associationAnswer: '',
      // 操作考题Param
      paperQuestionsParam: {},
      // 考题Param
      testQuestionParam: {
        // html地址
        htmlUrl: '',
        // 实验考试名称
        operationTestName: '',
        // 试卷pdf
        paperPdf: '',
        // 总分
        score: 0,
        // 学科（1物理 2生物 3化学）
        subjectType: '',
        // 	考题id  →→→→→→→→→→→→→→→→→→→→→→→→→→→
        // testQuestionId: '',
        // 使用次数
        times: 0,
        microscope: false,
        // 考题编码
        operationTestCode: '',
        paperless: false,
        aiMarking: false,
      },
      // 实验考试操作题目集合
      questionsParams: [
        // {
        //   // 实验操作题目id →→→→→→→→→→→→→→→→→→→→→→→→→→→
        //   examQuestionOperationTestId: '',
        //   // 实验操作项名称
        //   operationItemName: '',
        //   // id →→→→→→→→→→→→→→→→→→→→→→→→→→→
        //   itemId: '',
        //   // 	分数
        //   score: 0,
        //   // 排序字段
        //   sortNum: 0,
        // },
      ],
      // 电子试卷问题集合
      electronicPapersQuestionParams: [
        // {
        //   // 正确答案
        //   answer: '',
        //   // 	问题id →→→→→→→→→→→→→→→→→→→→→→→→→→→
        //   electronicPapersQuestionId: '',
        //   // 输入最大个数
        //   inputSize:'',
        //   // 	操作题评分项id →→→→→→→→→→→→→→→→→→→→→→→→→→→
        //   operationItemId: '',
        //   // 操作题评分项序号
        //   operationQuestionNum: 0,
        //   positionHeight: 0,
        //   positionLeft: 0,
        //   positionTop: 0,
        //   positionWidth: 0,
        //   // 问题类型(1:填空；2:简答；3:画图)
        //   questionType: 0,
        //   score:''
        //   // 排序字段
        //   sortNum: 0,
        //   // 前端样式
        //   style: '',
        // },
      ],
      imageAddress: this.$imageAddress,
    }
  },
  created() {},
  mounted() {
    if (!this.testId) {
      this.testIndex = 1
    }
    // console.log('testIndex ', this.testIndex)
    if (this.$route.query.Id) {
      this.testId = this.$route.query.Id
      this.echo(this.testId)
    } else {
      this.testQuestionParam.operationTestCode = Math.random()
        .toString(36)
        .slice(2)
        .substr(-10)
    }
  },
  methods: {
    // 分数处理
    getSummaries(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 0) {
          sums[index] = '总计'
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else if (index === len - 2) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = 'N/A'
          }
          //如果是除了第一列和最后一列的其他列，则显示为空
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 添加评分项
    addQuestionsParams() {
      // console.log(this.dialogTestQuestionParamText.operationTestName);
      // console.log(typeof this.dialogTestQuestionParamText.score);
      if (!this.dialogTestQuestionParamText.operationTestName) {
        return this.$message.warning('评分项为必填项')
      }
      if (!this.dialogTestQuestionParamText.score) {
        return this.$message.warning('分数需大于0')
      }
      if (this.dialogTestQuestionParamText.score <= 0) {
        return this.$message.warning('分数需大于0')
      }
      if (
        !/^(0|([1-9]\d*))(\.\d{1})?$/.test(
          this.dialogTestQuestionParamText.score
        )
      ) {
        return this.$message.warning('分数只能为数字，有且只有一位小数')
      }
      let li = {
        // 实验操作题目id
        // examQuestionOperationTestId: '',
        // 实验操作项名称
        operationItemName: '',
        // id
        // itemId: '',
        // 	分数
        score: 0,
        // 排序字段
        sortNum: 0,
      }
      // if (!this.testId) {
      // li.examQuestionOperationTestId = this.examQuestionOperationTestId
      // li.itemId = this.itemId
      // }
      li.operationItemName = this.dialogTestQuestionParamText.operationTestName
      li.score = this.dialogTestQuestionParamText.score
      li.sortNum = this.testIndex++
      this.questionsParams.push(li)
      this.dialogTestQuestionParam = false
      this.dialogTestQuestionParamText = {}
    },
    // 移除 (关联的考题会一并移除)
    deleteQuestionsParams(i, row) {
      this.$confirm('确定移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.questionsParams.splice(i, 1)
          this.deleteList(row)
          this.$message.success('移除成功!')
        })
        .catch(() => {
          this.$message.info('已取消移除')
        })
    },
    // 移除后删除绑定的考题
    deleteList(val) {
      this.electronicPapersQuestionParams =
        this.electronicPapersQuestionParams.filter(
          (item) => item.operationQuestionNum !== val
        )
      this.topicIndex = 0
      this.$forceUpdate()
    },
    // 添加填空题
    addTopicBtn(num) {
      if (!this.tableData.length) {
        return this.$message.warning('请先上传试卷pdf')
      }
      let topY = this.$refs.testPaperBox.scrollTop
      let li = {
        // 正确答案
        answer: '',
        // 画框背景
        backgroundImgUrl: '',
        // 	问题id
        electronicPapersQuestionId: '',
        // 	操作题评分项id
        // operationItemId: '',
        // 输入最大个数
        inputSize: '',
        // 操作题评分项序号
        operationQuestionNum: null,
        positionHeight: 100,
        positionLeft: 0,
        positionTop: topY,
        positionWidth: 100,
        // 问题类型(1:填空；2:简答；3:画图) 4单选 5多选
        questionType: 0,
        // 排序字段
        sortNum: 0,
        // 前端样式
        style: '',
        // 分数
        score: null,
      }
      if (!this.testId) {
        li.electronicPapersQuestionId = this.electronicPapersQuestionId
        li.operationItemId = this.operationItemId
      }
      // if(this.testId)
      // '单选题'和'多选题'需要添加额外对象
      const typesNeedExtraObject = [4, 5]
      li.questionType = num
      if (typesNeedExtraObject.includes(num)) {
        li.electronicPapersOptionParams = []
      }
      this.electronicPapersQuestionParams.push(li)
    },
    // 删除考题
    deleteBtn(i) {
      // console.log(i);
      // console.log(this.topicIndex);
      this.electronicPapersQuestionParams.splice(i, 1)
      // if (this.electronicPapersQuestionParams.length == 0 || this.topicIndex == i + 1) {
      //   this.topicIndex = 0
      // }
      this.topicIndex = 0
    },
    // 选中考题
    checkedBtn(val, i) {
      this.topicForm = val
      this.topicIndex = i + 1
      // console.log('评分项', this.questionsParams)
      // console.log('选中', this.topicForm)
      if (!!this.topicForm.operationQuestionNum) {
        // 显示关联第几题
        const index = this.questionsParams.findIndex(
          (item) => item.sortNum === this.topicForm.operationQuestionNum
        )
        if (index !== -1) {
          this.associationIndex = index + 1
        } else {
          this.associationIndex = '未关联'
        }
      }
    },
    // 打开关联弹框
    dialogVisibleShow() {
      this.associationAnswer = ''
      this.dialogVisible = true
    },
    // 关联确认
    association() {
      if (this.associationAnswer == '') {
        return this.$message.warning('请选择关联项')
      }
      // console.log('确定')
      this.topicForm.operationQuestionNum = this.associationAnswer
      // console.log('questionsParams', this.questionsParams)
      // console.log(
      //   'electronicPapersQuestionParams',
      //   this.electronicPapersQuestionParams
      // )
      for (let index = 0; index < this.questionsParams.length; index++) {
        //   const element = array[index];
        if (
          this.topicForm.operationQuestionNum ==
          this.questionsParams[index].sortNum
        ) {
          this.topicForm.score = this.questionsParams[index].score
          this.associationIndex = index + 1
          break
        }
      }
      this.dialogVisible = false
    },
    // 上传试卷PDF
    uploadPdfBtn(item) {
      // console.log('上传了')
      let fd = new FormData()
      // fd.append('code', 'default')
      fd.append('file', item.file)
      questions.importPdf(fd).then((res) => {
        // console.log('上传试卷PDF', res)
        if (res.success) {
          this.tableData.push(res.data)
          this.imgPdfUrl = this.$imageAddress(this.tableData[0].url)
          this.testQuestionParam.paperPdf = res.data.url
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 上传图片答案
    uploadImgAnswer(item) {
      let fd = new FormData()
      fd.append('code', 'default')
      fd.append('file', item.file)
      questions.importOne(fd).then((res) => {
        // console.log('上传图片答案', res)
        if (res.success) {
          // 关联的考题
          this.topicForm.answer = res.data.url
        }
      })
    },
    // 上传画框背景
    uploadImgBackgroundImgUrl(item) {
      let fd = new FormData()
      fd.append('code', 'default')
      fd.append('file', item.file)
      questions.importOne(fd).then((res) => {
        // console.log('上传画框背景', res)
        if (res.success) {
          // 关联的考题
          this.topicForm.backgroundImgUrl = res.data.url
        }
      })
    },
    // 处理考题样式
    tidyStyle(array) {
      array.forEach((val, i) => {
        // val.operationQuestionNum = JSON.parse(JSON.stringify(val.sortNum))
        val.sortNum = JSON.parse(JSON.stringify(i + 1))
        // if (val.questionType == 3) {
        //   // 画图题
        //   val.style = `<div class='canvasImgBox' onclick="clickImg(this)" style = 'width:${val.positionWidth}px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${val.positionLeft}px'><img src='' class='canvasImg' ></div>`
        // } else {
        //   // 字数限制
        //   val.inputSize = Math.floor((val.positionHeight / (this.fontSize + 4))) * Math.floor(((val.positionWidth - 2) / this.fontSize))
        //   // 填空简答题
        //   val.style = `
        //   <textarea onfocus="focusFunc(this)" onblur="blurFunc()" placeholder='输入答案' style = 'width:${val.positionWidth}px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${val.positionLeft}px;font-size:${this.fontSize}px;line-height:${this.fontSize + 4}px;' maxlength='${val.inputSize}'></textarea>
        //   <div class='textarea' style='width:${val.positionWidth}px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${val.positionLeft}px;font-size:${this.fontSize}px;line-height:${this.fontSize + 4}px;' maxlength='${val.inputSize}'></div>
        //   `
        // }
        if (val.questionType < 3) {
          // 字数限制
          val.inputSize =
            Math.floor(val.positionHeight / (this.fontSize + 4)) *
            Math.floor((val.positionWidth - 2) / this.fontSize)
          // 填空简答题
          val.style = `
          <textarea onfocus="focusFunc(this)" onblur="blurFunc()" placeholder='输入答案' style = 'width:${
            val.positionWidth
          }px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${
            val.positionLeft
          }px;font-size:${this.fontSize}px;line-height:${
            this.fontSize + 4
          }px;' maxlength='${val.inputSize}'></textarea>
          <div class='textarea' style='width:${val.positionWidth}px;height:${
            val.positionHeight
          }px;top:${val.positionTop}px;left:${val.positionLeft}px;font-size:${
            this.fontSize
          }px;line-height:${this.fontSize + 4}px;' maxlength='${
            val.inputSize
          }'></div>
          `
        } else if (val.questionType == 4) {
          // 单选
          val.style = `
          <textarea  placeholder='选择答案'  name='radio${i}' disabled style = 'background-color: transparent;width:${
            val.positionWidth
          }px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${
            val.positionLeft
          }px;font-size:${this.fontSize}px;line-height:${
            this.fontSize + 4
          }px;'></textarea>
          <div class='textarea' style='width:${val.positionWidth}px;height:${
            val.positionHeight
          }px;top:${val.positionTop}px;left:${val.positionLeft}px;font-size:${
            this.fontSize
          }px;line-height:${this.fontSize + 4}px;'></div>
          `
          if (val.electronicPapersOptionParams.length) {
            for (let j = 0; j < val.electronicPapersOptionParams.length; j++) {
              val.electronicPapersOptionParams[j].optionBoxStyle = `
              <label class='labelInp' style='width:${val.electronicPapersOptionParams[j].optionBoxPositionWidth}px;height:${val.electronicPapersOptionParams[j].optionBoxPositionHeight}px;top:${val.electronicPapersOptionParams[j].optionBoxPositionTop}px;left:${val.electronicPapersOptionParams[j].optionBoxPositionLeft}px;'>
              <input class='radioInp' type='radio' name='radio${i}' value='${val.electronicPapersOptionParams[j].optionContent}' onclick='radioFunc(this.value,this.name)' style = 'width:${val.electronicPapersOptionParams[j].optionBoxPositionWidth}px;height:${val.electronicPapersOptionParams[j].optionBoxPositionHeight}px;'>
              <div class='labelDiv'></div>
              </label>
              `
            }
          }
        } else if (val.questionType == 5) {
          // 多选
          val.style = `
          <textarea  placeholder='选择答案' name='checkbox${i}' disabled style = 'background-color: transparent;width:${
            val.positionWidth
          }px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${
            val.positionLeft
          }px;font-size:${this.fontSize}px;line-height:${
            this.fontSize + 4
          }px;'></textarea>
          <div class='textarea' style='width:${val.positionWidth}px;height:${
            val.positionHeight
          }px;top:${val.positionTop}px;left:${val.positionLeft}px;font-size:${
            this.fontSize
          }px;line-height:${this.fontSize + 4}px;'></div>
          `
          if (val.electronicPapersOptionParams.length) {
            for (let j = 0; j < val.electronicPapersOptionParams.length; j++) {
              val.electronicPapersOptionParams[j].optionBoxStyle = `
              <label class='labelInp labelCheckboxInp' style = 'width:${val.electronicPapersOptionParams[j].optionBoxPositionWidth}px;height:${val.electronicPapersOptionParams[j].optionBoxPositionHeight}px;top:${val.electronicPapersOptionParams[j].optionBoxPositionTop}px;left:${val.electronicPapersOptionParams[j].optionBoxPositionLeft}px;'>
              <input class='checkboxInp' type='checkbox' name='checkbox${i}' value='${val.electronicPapersOptionParams[j].optionContent}' onclick='checkFunc(this.name)'style = 'width:${val.electronicPapersOptionParams[j].optionBoxPositionWidth}px;height:${val.electronicPapersOptionParams[j].optionBoxPositionHeight}px;'>
              <div class='labelDiv'></div>
              </label>
              `
            }
          }
        } else {
          // 画图题
          val.style = `<div class='canvasImgBox' onclick="clickImg(this)" style = 'width:${val.positionWidth}px;height:${val.positionHeight}px;top:${val.positionTop}px;left:${val.positionLeft}px'><img src='' class='canvasImg' ></div>`
        }
      })
    },
    removePdf() {
      // ,此操作会清空考题
      this.$confirm('确定移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$refs.upload.clearFiles() // 清空历史记录
          this.tableData = []
          this.imgPdfUrl = ''
          this.testQuestionParam.paperPdf = ''
          // 清除选中的考题
          this.electronicPapersQuestionParams = []
          this.topicIndex = 0
          this.$message.success('移除成功!')
        })
        .catch(() => {
          this.$message.info('已取消移除')
        })
    },
    // 新增
    addTo() {
      if (this.testQuestionParam.subjectType != 2) {
        this.testQuestionParam.microscope = false
      }
      questions.questionsAdd(this.paperQuestionsParam).then((res) => {
        // console.log('新增', res)
        // 保存成功提示
        if (res.success) {
          this.$message.success('保存成功')
          setTimeout(() => {
            this.$router.replace(`/examQuestions/examQuestionsManage`)
          }, 500)
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 更新
    renew() {
      if (this.testQuestionParam.subjectType != 2) {
        this.testQuestionParam.microscope = false
      }
      questions.questionsUpdate(this.paperQuestionsParam).then((res) => {
        // console.log('更新的', this.paperQuestionsParam)
        // console.log('更新', res)
        if (res.success) {
          this.$message.success('编辑成功')
          setTimeout(() => {
            this.$router.replace(`/examQuestions/examQuestionsManage`)
          }, 500)
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    echo(id) {
      questions.questionDetails(id).then((res) => {
        // console.log('回显', res)
        if (res.success) {
          let { testQuestionDTO, itemDTOS, electronicPapersQuestionDTOS } =
            res.data
          if (electronicPapersQuestionDTOS.length) {
            this.electronicPapersQuestionId =
              electronicPapersQuestionDTOS[0].electronicPapersQuestionId
            this.operationItemId =
              electronicPapersQuestionDTOS[0].operationItemId
          }
          if (itemDTOS.length != 0) {
            this.examQuestionOperationTestId =
              itemDTOS[0].examQuestionOperationTestId
            this.itemId = itemDTOS[0].itemId
          }

          this.testQuestionParam = testQuestionDTO
          this.questionsParams = itemDTOS
          // this.electronicPapersQuestionParams = electronicPapersQuestionDTOS
          // 修改名字
          this.electronicPapersQuestionParams = JSON.parse(
            JSON.stringify(electronicPapersQuestionDTOS).replace(
              /electronicPapersOptionDTOS/g,
              'electronicPapersOptionParams'
            )
          )

          if (!this.testQuestionParam.microscope) {
            this.testQuestionParam.microscope = false
          }

          // 处理图片显示 图片名称 等等
          if (this.testQuestionParam.paperPdf) {
            this.imgPdfUrl = this.$imageAddress(this.testQuestionParam.paperPdf)
            let li = {
              fileName: 0,
              url: this.testQuestionParam.paperPdf,
              size: 0,
            }
            this.tableData.push(li)
          }
          // 获取新的sortNum
          if (this.questionsParams.length) {
            this.testIndex =
              this.questionsParams[this.questionsParams.length - 1].sortNum + 1
          } else {
            this.testIndex = 1
          }
        }
      })
    },
    // 保存
    SaveSubmit() {
      // console.log(this.electronicPapersQuestionParams)
      let judgment = false
      let judgmentText = ''
      if (this.testQuestionParam.paperless) {
        for (
          let index = 0;
          index < this.electronicPapersQuestionParams.length;
          index++
        ) {
          // if (!this.electronicPapersQuestionParams[index].sortNum) judgment = true
          if (
            this.electronicPapersQuestionParams[index].questionType > 3 &&
            this.electronicPapersQuestionParams[index].questionType < 6
          ) {
            if (
              !this.electronicPapersQuestionParams[index]
                .electronicPapersOptionParams?.length
            ) {
              judgment = true
              judgmentText = '选择题选项不能为空'
              break
            } else {
              for (
                let j = 0;
                j <
                this.electronicPapersQuestionParams[index]
                  .electronicPapersOptionParams.length;
                j++
              ) {
                const ele =
                  this.electronicPapersQuestionParams[index]
                    .electronicPapersOptionParams[j]
                if (!ele.optionContent.length) {
                  judgment = true
                  judgmentText = '选择题选项答案不能为空'
                  break
                }
              }
            }
          }
        }
      }
      if (judgment) return this.$message.warning(judgmentText)
      if (this.testQuestionParam.operationTestCode == '') {
        return this.$message.warning('考题编码不能为空')
      }
      // if (this.testQuestionParam.operationTestCode.length != 10) { return this.$message.warning('考题编码限制10位') }
      if (this.testQuestionParam.operationTestName == '') {
        return this.$message.warning('请输入题目名称')
      }
      if (this.testQuestionParam.subjectType == '') {
        return this.$message.warning('请选择相关学科')
      }

      this.tidyStyle(this.electronicPapersQuestionParams)
      this.paperQuestionsParam = {}
      this.paperQuestionsParam.electronicPapersQuestionParams =
        this.electronicPapersQuestionParams
      this.paperQuestionsParam.questionsParams = this.questionsParams
      this.paperQuestionsParam.testQuestionParam = this.testQuestionParam

      // console.log('↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓')
      if (this.testId) {
        this.renew()
      } else {
        this.addTo()
      }
      // console.log('↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑')
    },
    // 添加选项
    addChooseBtn() {
      let topY = this.$refs.testPaperBox.scrollTop
      let li = {
        optionContent: '',
        correct: false,
        optionBoxPositionLeft: 0,
        optionBoxPositionTop: topY,
        optionBoxStyle: '',
        // 新加宽高
        optionBoxPositionWidth: 36,
        optionBoxPositionHeight: 36,
        // electronicPapersOptionId: 0,
        // electronicPapersQuestionId: 0,
      }
      this.topicForm.electronicPapersOptionParams.push(li)
    },
    // 删除选项
    deleteChooseBtn() {
      this.topicForm.electronicPapersOptionParams.pop()
    },

    // 单选正确答案
    radioChange(val, i) {
      // 清空正确答案
      this.topicForm.answer = ''
      if (val) {
        this.topicForm.answer =
          this.topicForm.electronicPapersOptionParams[i].optionContent
      }
      this.topicForm.electronicPapersOptionParams.forEach((el, index) => {
        if (index !== i) el.correct = false
      })
    },
    multipleRadioChange() {
      let correctText = ''
      this.topicForm.electronicPapersOptionParams.forEach((el) => {
        // console.log('el', el)
        if (el.correct) {
          // correctText += el.optionContent +'&'
          correctText = correctText.concat('&', el.optionContent)
        }
      })

      this.topicForm.answer = correctText.slice(1)
    },
  },
  // 自定义指令 实现可拖动
  directives: {
    drag(el, bindings) {
      el.onmousedown = function (e) {
        if (e.button == 2) {
          var disX = e.pageX - el.offsetLeft
          var disY = e.pageY - el.offsetTop
          document.onmousemove = function (e) {
            el.style.left = e.pageX - disX + 'px'
            el.style.top = e.pageY - disY + 'px'
          }
        }
        document.onmouseup = function (e) {
          // bindings.value.width = el.offsetWidth
          // bindings.value.height = el.offsetHeight
          // console.log(el)
          // console.log(el.children[0])
          // console.log('bindings', bindings.value)
          // console.log('bindings', bindings.value)
          // bindings.value.positionWidth = el.children[0].offsetWidth
          // bindings.value.positionHeight = el.children[0].offsetHeight
          if (bindings.value.positionHeight) {
            bindings.value.positionWidth = el.children[0].clientWidth
            bindings.value.positionHeight = el.children[0].clientHeight
            bindings.value.positionLeft = parseInt(
              el.style.left ? el.style.left : 0
            )
            bindings.value.positionTop = parseInt(
              el.style.top ? el.style.top : 0
            )
          } else {
            bindings.value.optionBoxPositionHeight = el.children[0].clientHeight
            bindings.value.optionBoxPositionWidth = el.children[0].clientWidth
            bindings.value.optionBoxPositionLeft = parseInt(
              el.style.left ? el.style.left : 0
            )
            bindings.value.optionBoxPositionTop = parseInt(
              el.style.top ? el.style.top : 0
            )
          }

          document.onmousemove = document.onmouseup = null
        }
      }
    },
  },
  // 过滤器
  filters: {
    filterSubject(val, i = '') {
      const questionTypes = {
        1: '填空题',
        2: '简答题',
        3: '画图题',
        4: '单选题',
        5: '多选题',
        6: '公式画图题',
        7: '读数识别填空题',
      }

      return `${i}${questionTypes[val] || '参数错误'}`
    },
    multipleRadioAnswer(val) {
      return val.replaceAll('&', '、')
    },
  },
  watch: {
    // 'testQuestionParam.operationTestCode' (newValue, oldValue) {
    //   this.testQuestionParam.operationTestCode = newValue.replace(
    //     /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5_]/g,
    //     ''
    //   )
    // },
    'testQuestionParam.paperless'(val) {
      if (!val) {
        // console.log('关闭考题')
        // 清除选中的考题
        this.electronicPapersQuestionParams = []
        this.topicIndex = 0
      }
    },
  },
}
</script>

<style lang="scss">
.examQuestionsEditBox {
  // display: flex !important;
}

.examQuestionsEditLeft {
  // flex: 1;
  width: calc(100% - 1020px);

  .el-form-item {
    margin-bottom: 16px;
  }

  .chooseTopicBox {
    border-bottom: 1px solid #ebeef5;
    border-top: 1px solid #ebeef5;
    padding: 15px 0 10px 0;

    .chooseTopicTit {
      font-weight: bolder;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .addTopicBtn {
      background: #dfe6ec;
      text-align: center;
      margin: 0 10px 10px;
      box-sizing: border-box;
      padding: 10px 16px;
      cursor: pointer;
      border-radius: 5px;
      min-width: 12%;
    }

    .chooseTopicList {
      display: flex;
      flex-wrap: wrap;

      .chooseTopic {
        display: inline-block;
        border: 1px solid #cbd8f5;
        padding: 10px;
        padding-right: 22px;
        margin: 0 10px 10px 0;
        margin-left: 0;
        min-width: 106px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        position: relative;

        i {
          display: none;
          position: absolute;
          right: 6px;
          top: calc(50% - 8px);
          width: 16px;
          height: 16px;
          cursor: pointer;
          color: rgba(255, 0, 0, 0.801);
        }

        &:hover {
          i {
            display: block;
          }
        }
      }
    }

    .chooseTopicChecked {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #cbd8f5;
      padding: 18px;
      padding-left: 0;
      padding-bottom: 0;

      .answerImg {
        width: 100px;
        height: 100px;
        border: 1px solid #cbd8f5;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

.examQuestionsEditRight {
  // width: 1020px !important;
  // flex: 0 0 1020px;
  height: calc(100vh - 108px);
  overflow-y: auto;
  width: 1020px;
  position: fixed;
  right: 20px;
  top: 108px;

  .upBtn {
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 10px;

    span {
      display: inline-block;
      margin: 0 10px;
    }
  }

  .el-table {
    box-sizing: border-box;
    // padding: 0 20px;
    margin: 0 20px;
    text-align: center;
    height: 110px !important;
  }

  .testPaperBox {
    width: 1020px;
    height: calc(100vh - 256px);
    overflow-y: auto;
  }

  .testPaper {
    width: 1020px;
    position: relative;

    .testPaperImg {
      width: 1020px;
    }

    & > div {
      cursor: pointer;
      position: absolute;
      z-index: 5;
      // background: rgba(255, 217, 217, 0.479);
      left: 0;
      top: 0;
      min-width: 10px;
      min-height: 10px;

      textarea {
        background: #dfe6eca1;
        border: 1px solid #ccc;
        text-align: center;
        width: 100%;
        height: 100%;
      }
    }

    span {
      position: absolute;
      left: 0;
      bottom: 0;
      pointer-events: none;
    }
  }
}

.textareaBox {
  width: 100%;
  height: 100%;

  textarea {
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
}

.radioCon {
  position: absolute;
  min-width: 34px;
  // line-height: 34px;
  min-height: 34px;
  text-align: center;
  background: #dfe6ecab;
  box-sizing: border-box;
  border: 1px solid #dfe6ec;
  border-radius: 50%;
}

.multipleRadioCon {
  border-radius: 8px;
}

.radioCon textarea {
  border-radius: 50%;
  border-bottom-right-radius: 0;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.multipleRadioCon textarea {
  border-radius: 8px;
  border-bottom-right-radius: 0;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.el-col-span5 {
  width: 20%;
}

.radioBtnS {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  background: #dfe6ec;
  border-radius: 50%;
  cursor: pointer;
}

.multipleRadioBtnS {
  border-radius: 8px;
}

.radio-el {
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 3;
}

.radio-el2 {
  z-index: 5;
  opacity: 0;
}
</style>
